@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  color: #fff;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.bgBanner {
  background-color: rgba(0, 0, 0, 0.5);
}

label {
  margin-left: 18px;
  font-size: 30px;
  margin-right: 32px;
  margin-top: 30px;

}

.bgApros {
  /*background-image: url("../src/assets/project/");*/
  background-repeat: no-repeat;
  background-size: cover;
}


.contentInput div div input {
  padding: 18px;
  background: red;
}

input[type="radio"] {
  color: blueviolet;
}

input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
input[type="radio"]+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
input[type="radio"]+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
input[type="radio"]:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}
input[type="radio"]:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
input[type="radio"]:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
input[type="radio"]:focus:not(:checked)+label::before {
  border-color: #80bdff;
}
input[type="radio"]:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
input[type="radio"]:disabled+label::before {
  background-color: #e9ecef;
}

input[type="radio"]:checked + label {
  font-weight: bold;
}

.jobT {
  background: #ffc600;
  -webkit-transform: skew(-13deg);
  -ms-transform: skew(-13deg);
  transform: skew(-13deg);
  padding: 4px 10px;
  font-weight: 700;
}

.caption {
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  margin-bottom: -10px;
  color: #fff;
}

.videoB {
  transform: scale(1);
}

.bgBanner {
  background-color: rgba(0, 0, 0, 0.5);
}

.swiper, swiper-container {
  margin-left: 0 !important;
  z-index: 0 !important;
}

.bgBannerImgContact {
  background-image: url("./assets/contact/121212212112 (2).jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.gridProject {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.blockProject:hover img {
  transform: scale(1.2);
}

.blockProject:hover div {
  opacity: 1;
}

@media (max-width: 3500px) {
  .videoB {
    transform: scaleX(1.1);
  }
}

@media (max-width: 1700px) {
  .gridProject {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  label {
    font-size: 20px;
  }


}

@media (max-width: 1300px) {
  .videoB {
    transform: scale(2.7);
  }
}


@media (max-width: 1100px) {
  .gridProject {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  label {
    font-size: 20px;
    margin-right: 0;
  }

}

